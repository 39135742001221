import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { jobListLens, JOBS_LIST } from './utils';
import {
  JobsListQueryVariables,
  JobSortDirection,
  JobStatus,
} from 'src/__generated__/graphql';
import { startOfDay } from '@/utils/helper';

export const futureJobsVariables: JobsListQueryVariables = {
  filter: {
    jobStatus: ['claimed', 'submitted', 'pending_invoice', 'invoiced'],
    startGte: startOfDay()?.toISOString() || new Date().toISOString(),
    sortDir: JobSortDirection.Asc,
  },
  first: 2,
};

const useGetFutureJobs = () => {
  const {
    data: futureJobsData,
    fetchMore: _fetchMoreFutureJobs,
    loading: futureJobsLoading,
  } = useQuery(JOBS_LIST, {
    variables: futureJobsVariables,
    fetchPolicy: 'cache-and-network',
  });
  const jobList = useMemo(
    () =>
      jobListLens(futureJobsData).filter((j) => {
        // Filter out jobs that have been invoiced, since they are no longer
        // upcoming.
        if (j.status === JobStatus.Invoiced) return false;
        return true;
      }),
    [futureJobsData],
  );
  const fetchMoreFutureJobs = useCallback(() => {
    _fetchMoreFutureJobs({
      variables: {
        after:
          futureJobsData?.me?.__typename === 'Customer'
            ? futureJobsData.me.jobs?.pageInfo?.endCursor
            : null,
      },
    });
  }, [_fetchMoreFutureJobs, futureJobsData]);

  return { futureJobsData, jobList, futureJobsLoading, fetchMoreFutureJobs };
};

export default useGetFutureJobs;
