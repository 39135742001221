import React, { createContext, useContext, PropsWithChildren } from 'react';
import { makeLogger } from '../utils/makeLogger';

const log = makeLogger('useBrandData');

interface BrandDataState {
  logo: string;
  appName: string;
  baseUrl: string;
  dealsUrl: string;
  cookieyes: string;
  isDev?: boolean;
  newLogoSizing?: boolean;
  // Favicons, eg. "homeaglow" for /homeaglow.svg and /homeaglow.png
  // Also used for apple touch icons and manifest paths
  faviconKey: string;
  // If we are on old favicons and don't have the svg, manifest, and png
  // renditions
  icoOnlyFavicon: boolean;
}

const defaultBrandData: BrandDataState = {
  logo: '/homeaglow_logo.svg',
  newLogoSizing: true,
  appName: 'Homeaglow',
  baseUrl: 'http://www.homeaglowstaging.com',
  dealsUrl: 'http://try.homeaglowstaging.com/deal',
  cookieyes: 'de5a1994dc11dd30dc0c532b',
  faviconKey: 'homeaglow',
  icoOnlyFavicon: false,
};

const BrandDataContext = createContext(defaultBrandData);

export const useBrandData = () => useContext(BrandDataContext);

const brandDict: Record<string, BrandDataState> = {
  localhost: {
    appName: 'Homeaglow',
    baseUrl:
      process.env.NEXT_PUBLIC_API_URL ?? 'https://www.homeaglowstaging.com',
    dealsUrl: 'https://try.homeaglowstaging.com/deal',
    logo: '/homeaglow_logo.svg',
    newLogoSizing: true,
    cookieyes: 'de5a1994dc11dd30dc0c532b',
    isDev: true,
    faviconKey: 'homeaglow',
    icoOnlyFavicon: false,
  },
  openplatform: {
    appName: 'Cleaner Connect',
    baseUrl: 'https://www.cleanerconnect.com',
    logo: '/cleanerconnect_logo.png',
    dealsUrl: 'https://try.cleanerconnect.com/deal',
    cookieyes: 'e5765bc660dd9809ba610d94',
    faviconKey: 'openplatform',
    icoOnlyFavicon: true,
  },
  homeaglow: {
    appName: 'Homeaglow',
    baseUrl: 'https://www.homeaglow.com',
    logo: '/homeaglow_logo.svg',
    newLogoSizing: true,
    dealsUrl: 'https://try.homeaglow.com/deal',
    cookieyes: 'cd4507cc9a65095919adea23',
    faviconKey: 'homeaglow',
    icoOnlyFavicon: false,
  },
  bubblycleaning: {
    appName: 'Bubbly Cleaning',
    baseUrl: 'https://www.bubblycleaning.com',
    logo: '/bubblycleaning_logo.png',
    dealsUrl: 'https://try.bubblycleaning.com/deal',
    cookieyes: '60e3602394f2450668a8508e',
    faviconKey: 'bubblycleaning',
    icoOnlyFavicon: true,
  },
  cozymaid: {
    appName: 'Cozy Maid',
    baseUrl: 'https://www.cozymaid.com',
    logo: '/cozymaid_logo.png',
    dealsUrl: 'https://try.cozymaid.com/deal',
    cookieyes: 'b1a56a897750d20d27f69858',
    faviconKey: 'cozymaid',
    icoOnlyFavicon: true,
  },
  dappermaids: {
    appName: 'Dapper Maids',
    baseUrl: 'https://www.dappermaids.com',
    logo: '/dappermaids_logo.png',
    dealsUrl: 'https://try.dappermaids.com/deal',
    cookieyes: '8f819c689d6fc624f5e7a716',
    faviconKey: 'dappermaids',
    icoOnlyFavicon: true,
  },
  snazzymaids: {
    appName: 'Snazzy Maids',
    baseUrl: 'https://www.snazzymaids.com',
    logo: '/snazzymaids_logo.png',
    dealsUrl: 'https://www.snazzymaids.com/customer/choosedate',
    cookieyes: 'f9d966fca95e9893d0c6849f',
    faviconKey: 'snazzymaids',
    icoOnlyFavicon: false,
  },
  atmaid: {
    appName: 'AT Maid',
    baseUrl: 'https://www.atmaid.com',
    logo: '/atmaid_logo.png',
    dealsUrl: 'https://try.atmaid.com/deal',
    cookieyes: '4f96225fecd3d493c32372a0',
    faviconKey: 'atmaid',
    icoOnlyFavicon: true,
  },
  cleanerlove: {
    appName: 'Cleaner Love',
    baseUrl: 'https://www.cleanerlove.com',
    logo: '/cleanerlove_logo.png',
    dealsUrl: 'https://try.cleanerlove.com/deal',
    cookieyes: '2a50cbb97c418627e00b13b5',
    faviconKey: 'cleanerlove',
    icoOnlyFavicon: true,
  },
  dazzlingcleaning: {
    appName: 'Dazzling Cleaning',
    baseUrl: 'https://www.dazzlingcleaning.com',
    logo: '/dazzlingcleaning_logo.png',
    dealsUrl: 'https://try.dazzlingcleaning.com/deal',
    cookieyes: '79ea962d26b6aa767b2721a9',
    faviconKey: 'dazzlingcleaning',
    icoOnlyFavicon: true,
  },
  homeaglowstaging: {
    appName: 'Homeaglow Staging',
    baseUrl: 'https://www.homeaglowstaging.com',
    logo: '/homeaglow_logo.svg',
    newLogoSizing: true,
    dealsUrl: 'https://try.homeaglowstaging.com/deal',
    cookieyes: 'de5a1994dc11dd30dc0c532b',
    faviconKey: 'homeaglow',
    icoOnlyFavicon: false,
  },
  vercel: {
    appName: 'Homeaglow Staging',
    baseUrl: 'https://www.homeaglowstaging.com',
    logo: '/homeaglow_logo.svg',
    newLogoSizing: true,
    dealsUrl: 'https://try.homeaglowstaging.com/deal',
    cookieyes: 'de5a1994dc11dd30dc0c532b',
    faviconKey: 'homeaglow',
    icoOnlyFavicon: false,
  },
};

const _appName = (host?: string) => {
  const baseNameDivisor = '.';
  // server-side, no document
  if (host) {
    const splitUrl = host.split(baseNameDivisor);
    if (host.startsWith('localhost')) {
      log('[Server] appName: ', 'localhost');
      return 'localhost';
    }
    log('[Server] appName', splitUrl[1]);
    return splitUrl[1];
  }
  // client side -- no document found (not sure if this can be hit, but
  // is here as a safety net)
  if (typeof document === 'undefined') {
    log('server');
    return 'localhost';
  }
  const documentURl = document.URL;
  const splitUrl = documentURl.split(baseNameDivisor);
  if (documentURl.startsWith('http://localhost')) {
    log('appName', 'localhost');
    return 'localhost';
  }
  log('appName', splitUrl[1]);
  return splitUrl[1];
};

export const BrandDataProvider: React.FC<
  PropsWithChildren<{ host?: string }>
> = ({ host, children }) => {
  // the name from the url, like `localhost` or `homeaglowstaging`
  const appName = _appName(host);
  const brandData = brandDict[appName || 'localhost'] || brandDict.localhost;

  return (
    <BrandDataContext.Provider value={brandData}>
      {children}
    </BrandDataContext.Provider>
  );
};

// we have to export baseUrl separately, since this is used to setup the
// api client, and we need that outside of a hook, at the root of the app,
// so we can't use the useBrandData hook. This may be imprecise on the server,
// but that's okay
const appName = _appName();
const brand = brandDict[appName || 'localhost'] || brandDict.localhost;
export const baseUrl = brand!.baseUrl;
