import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { baseUrl as baseUrlForApp } from '../hooks/useBrandData';
import { relayStylePagination } from '@apollo/client/utilities';
import { createFetcher, setOnBadToken } from '../utils/createFetcher';
import { RetryLink } from '@apollo/client/link/retry';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import toast from '../utils/toast';
import { ExperimentLookupConfig } from 'src/__generated__/graphql';

// if baseURL is undefined, it will reference the host (/), so this still works
// for prod when baseURL isn't there
const baseURL = process.env.NEXT_PUBLIC_API_URL
  ? process.env.NEXT_PUBLIC_API_URL
  : baseUrlForApp
    ? baseUrlForApp
    : undefined;

const httpLink = new HttpLink({
  fetch: createFetcher(baseURL + '/api'),
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error) => {
      return (
        error?.statusCode && error.statusCode < 500 && error.statusCode !== 401
      );
    },
  },
});

const possibleTypes = {
  Node: [
    'CustomerReferral',
    'Cleaner',
    'ZipCode',
    'Job',
    'Customer',
    'ForeverCleanMembership',
    'CustomerCohort',
    'CustomerLocation',
    'CustomerProspect',
    'Voucher',
    'SavedPaymentMethod',
    'CleanerTravelLog',
    'AlternateStartTime',
    'RecurringContract',
    'Review',
    'AdvancedReview',
    'Conversation',
    'Agent',
    'Asset',
    'CleanerChatMessage',
    'CleanerCustomerLocationMessage',
    'CleanerCustomerInTransitMessage',
    'CleanerCustomerOnsiteMessage',
    'CleanerCustomerUpdateETAMessage',
    'CustomerChatMessage',
  ],
  LegacyCustomerAlert: [
    'FirstJobAlert',
    'SystemCancelJobAlert',
    'FailedChargesAlert',
    'CreditsAvailableAlert',
  ],
  PendingPricingEstimateResult: [
    'PendingPricingEstimate',
    'InvalidZipcodeError',
    'JobNotPendingError',
  ],
  LegacyCustomerJobAlert: [
    'CPRescheduledNotRequestedAlert',
    'CPSwappedAlert',
    'HoursChangedCPNotApprovedAlert',
    'ChargedForLastMinuteCancelAlert',
    'ChargedForLockoutAlert',
  ],
  ConversationCustomer: ['AdminMessageUser', 'CustomerProspect'],
  ConversationCustomerUser: ['AdminMessageUser', 'CleanerProspect'],
  CleanerMessage: [
    'CleanerChatMessage',
    'CleanerCustomerLocationMessage',
    'CleanerCustomerInTransitMessage',
    'CleanerCustomerOnsiteMessage',
    'CleanerCustomerUpdateETAMessage',
  ],
  CustomerMessage: ['CustomerChatMessage', 'CustomerCleanerRebookMessage'],
  ServiceRegionResult: [
    'ServiceRegion',
    'ZipcodeFormatError',
    'ServiceRegionUnsupportedError',
  ],
  User: ['Cleaner', 'Customer', 'Agent'],
  AgentSearchResult: ['Cleaner', 'Customer'],
  MutationResponse: [
    'CreateCleanerCheckin',
    'CreateCleanerTravelLog',
    'UpdateETAResponse',
    'CancelJobResponse',
    'ClaimJobResponse',
    'CreateCleanerMessage',
    'CreateCustomerReviewByCleanerResponse',
    'DeactivateCleaner',
    'MarkCleanerMessageRead',
    'RemoveJobResponse',
    'ReportCleanerIssueResponse',
    'RescheduleJobResponse',
    'UpdateCleanerAccountInformation',
    'UpdateCleanerCoverage',
    'UpdateCleanerPayRate',
    'UpdateCleanerServices',
    'ApplyForeverCleanRetentionOffer',
    'CancelCustomerJobs',
    'CancelCustomerJobResponse',
    'GenerateCustomerReferral',
    'CancelForeverCleanMembership',
    'CreateCustomerLocation',
    'CreatePendingCustomerJob',
    'OneClickCancelForeverCleanMembership',
    'PurchaseVoucher',
    'RefundVoucher',
    'RegisterLead',
    'SubmitCustomerJob',
    'SubmitSourceSurvey',
    'UpdateCustomerAccountInformation',
    'UpdateCustomerJob',
    'UpdateCustomerLocation',
    'UpdateCustomerSavedPayment',
    'SetCustomerRequestedCleaners',
    'UpdateCustomerProfileAttributes',
    'CreateAutoLoginUrl',
    'CreatePageLoad',
    'CreateUserUploadResponse',
    'RegisterMobileDevice',
    'UpdateUserPassword',
  ],
};

const client = new ApolloClient({
  link: from([retryLink, httpLink]),
  name: 'Customer Web',
  cache: new InMemoryCache({
    possibleTypes,
    typePolicies: {
      Query: {
        fields: {
          // This allows us to improve cache hits from different keys when using the useExperiment hook
          experiments: {
            read(_, { args, toReference, canRead }) {
              if (!args) return;
              // check the cache for existing keys
              const found = (args.keysWithOptions as ExperimentLookupConfig[])
                .map((keyWithOptions: ExperimentLookupConfig) =>
                  toReference({
                    __typename: 'ExperimentResponse',
                    key: keyWithOptions.key,
                  }),
                )
                .filter((ref) => !!ref && canRead(ref));
              // if we haven't found all of the keys, we can't return partial so just signal
              // to Apollo to fetch them all
              if (found.length === args.keysWithOptions.length) {
                return found;
              }
            },
          },
        },
      },
      ExperimentResponse: {
        keyFields: ['key'],
      },
      Customer: {
        fields: {
          suggestedCleaners: relayStylePagination(),
          jobs: relayStylePagination(['filter']),
          cashOutReceipts: relayStylePagination(),
          conversation: {
            read(_, { args, toReference }) {
              if (!args) return;
              return toReference({
                __typename: 'CustomerConversation',
                recipient: { rawId: args.cleanerprospectId },
              });
            },
          },
          conversations: relayStylePagination(),
        },
      },
      Cleaner: {
        fields: {
          reviews: relayStylePagination(),
        },
      },
      CustomerConversation: {
        keyFields: ['recipient', ['rawId'] as any],
        fields: { messages: relayStylePagination() },
      },
    },
  }),
});

export default function ApolloProviderWithAxiosFetch({ children }: any) {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady)
      setOnBadToken(() => {
        router.push('/sign-in');
        toast.error('Session expired. Please sign in again.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
