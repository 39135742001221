'use client';
import { useBrandData } from '@/hooks/useBrandData';
import Script from 'next/script';

import { useEffect } from 'react';

function cookieListener() {
  const wrapper = document.getElementsByClassName('cky-notice-btn-wrapper')[0];
  const acceptBtn = document.createElement('button');
  acceptBtn.classList.add('cky-btn', 'cky-btn-accept');
  const acceptBtnText = document.createTextNode('Accept');
  acceptBtn.append(acceptBtnText);
  acceptBtn.addEventListener('click', function () {
    // @ts-expect-error ...
    window.performBannerAction('accept_all');
  });
  wrapper.append(acceptBtn);
  const noticeDes = document.getElementsByClassName('cky-notice-des')[0];
  noticeDes.getElementsByTagName('p')[0].innerText =
    'We use cookies on this site to improve your browsing experience, analyze individual usage and website traffic, and personalize content to your preferences.';
  const learnBtn = document.getElementsByClassName('cky-btn-do-not-sell')[0];
  learnBtn.classList.add('cky-btn-customize');
  learnBtn.classList.remove('cky-btn-do-not-sell');
  // @ts-expect-error ...
  learnBtn.innerText = 'Learn more';
}

export function CookieBlocker() {
  const { cookieyes, isDev } = useBrandData();
  // this is a hacky way to make a US style cookie banner look like a GDPR one
  useEffect(() => {
    document.addEventListener('cookieyes_banner_load', cookieListener);
    return () => {
      document.removeEventListener('cookieyes_banner_load', cookieListener);
    };
  }, []);
  if (isDev) return null;
  return (
    <Script
      src={`https://cdn-cookieyes.com/client_data/${cookieyes}/script.js`}
    />
  );
}
