import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';

type Width = 'wide' | 'narrow' | 'extraNarrow';

interface LayoutContext {
  setBackButtonAction: (act: any) => void;
  clearBackButtonAction: () => void;
  backButtonAction: undefined | (() => void);
  setCloseButtonAction: (act: any) => void;
  clearCloseButtonAction: () => void;
  closeButtonAction: undefined | (() => void);
  setWidth: (_width: Width) => void;
  width: Width;
}

const layoutContext = createContext<LayoutContext>({
  setBackButtonAction: () => {},
  clearBackButtonAction: () => {},
  backButtonAction: undefined,
  setCloseButtonAction: () => {},
  clearCloseButtonAction: () => {},
  closeButtonAction: undefined,
  setWidth: () => {},
  width: 'narrow',
});

// Provider component that wraps the app and makes the layout controls
// available to any child component that calls useLayoutControls().
export function LayoutControlsProvider({ children }: { children: ReactNode }) {
  const ctx = useLayoutControlsProvider();
  return (
    <layoutContext.Provider value={ctx}>{children}</layoutContext.Provider>
  );
}
export const useLayoutControls = () => {
  return useContext(layoutContext);
};

const useLayoutControlsProvider = () => {
  const [backButtonAction, setBackButtonAction] = useState<any>(undefined);
  const [closeButtonAction, setCloseButtonAction] = useState<any>(undefined);
  const [width, setWidth] = useState<Width>('narrow');
  // The use callback here is so that we can use clearBackButtonAction inside of a setBackButtonAction call
  // Without it, we get an infinite useEffect loop
  const clearBackButtonAction = useCallback(() => {
    setBackButtonAction(undefined);
  }, []);

  const clearCloseButtonAction = useCallback(() => {
    setCloseButtonAction(undefined);
  }, []);

  return {
    setBackButtonAction,
    clearBackButtonAction,
    backButtonAction,
    setCloseButtonAction,
    clearCloseButtonAction,
    closeButtonAction,
    setWidth,
    width,
  };
};
